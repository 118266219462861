<div class="modal fade" tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="confirmUserTitle">
    <div class="modal-dialog" role="document">
        <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise">
            <div class="modal-header">
                <h2 class="modal-title" id="confirmUserTitle">
                    {{'confirmUser' | i18n}}
                    <small class="text-muted" *ngIf="name">{{name}}</small>
                </h2>
                <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    {{'fingerprintEnsureIntegrityVerify' | i18n}}
                    <a href="https://dev-docs.rayt.io/docs/article/features/fingerprint-phrase" target="_blank" rel="noopener">
                        {{'learnMore' | i18n}}</a>
                </p>
                <p><code>{{fingerprint}}</code></p>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="dontAskAgain" name="DontAskAgain"
                        [(ngModel)]="dontAskAgain">
                    <label class="form-check-label" for="dontAskAgain">
                        {{'dontAskFingerprintAgain' | i18n}}
                    </label>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    <span>{{'confirm' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary"
                    data-dismiss="modal">{{'cancel' | i18n}}</button>
            </div>
        </form>
    </div>
</div>
